<template>
  <ul class="tabbar">
    <li v-for="(item,index) in list" :name="index" :key="index" @click.stop="changIndex(index)">
      <img :src="item.icon_unchecked" alt v-show="selected!=index" />
      <img :src="item.icon_checked" alt v-show="selected==index" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tabbar",
  data() {
    return {
      activeIndex: this.selected,
      list: [
        {
          id: 1,
          title: "在线拍卖",
          icon_unchecked: require("assets/images/tab_btn_auction_unchecked.png"),
          icon_checked: require("assets/images/tab_btn_auction_sel.png"),
          url: "/"
        },
        {
          id: 2,
          title: "我的竞拍",
          icon_unchecked: require("assets/images/tab_btn_bidding_unchecked.png"),
          icon_checked: require("assets/images/tab_btn_bidding_sel.png"),
          url: "/auction"
        },
        {
          id: 3,
          title: "会员中心",
          icon_unchecked: require("assets/images/tab_btn_vip_unchecked.png"),
          icon_checked: require("assets/images/tab_btn_vip_sel.png"),
          url: "/mine"
        }
      ]
    };
  },
  props: {
    selected: {
      type: [Number,String],
      default: 0
    },
    default: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.default) {
      this.activeIndex = this.selected;
      this.list.forEach((item, index) => {
        if (this.$route.path == item["url"]) {
          this.activeIndex = index;
        }
      });
    }
  },
  methods: {
    changIndex(v){
      let path = this.list[v]["url"];
      if (this.$route.path == path) return;
      this.$router.push({ path });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.tabbar {
  display: flex;
  align-items: center;
  position: absolute;
  height: 60px;
  background-color: white;
  width: 100%;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  li {
    cursor: pointer;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-of-type(1) {
      img {
        width: 43.5px;
      }
    }
    &:nth-of-type(2) {
      img {
        width: 43px;
      }
    }
    &:nth-of-type(3) {
      img {
        width: 44px;
      }
    }
  }
}
</style>