<template>
  <div class="order-page">
    <Head title="我的订单" />
    <ul class="auction-nav" ref="nav">
      <span
        class="bar"
        :style="{ left: activeIndex * (100 / navList.length) + '%' }"
      ></span>
      <li
        @click.stop="activeIndex = index"
        :class="{ active: activeIndex == index }"
        v-for="(item, index) in navList"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>

    <div class="order-pay" v-if="activeIndex == 1">
      <div>
        <button @click.stop="all = !all">全选</button>
        <button @click.stop="sendPay(null)">合并支付(享运费减免)</button>
      </div>
      <p>
        <span>数量：{{ selected.length }}</span>
        <span>金额：¥{{ money }}</span>
        <br>
          <span>运费：¥10</span>   
      </p>
      
    </div>
    <Warpper bottom="60" :top="activeIndex == 1 ? '133' : '84'">
      <div
        class="page-loadmore-wrapper"
        style="overflow: auto; height: 100%; position: relative"
      >
        <mt-loadmore
          :bottom-method="loadBottom"
          @bottom-status-change="handleBottomChange"
          :bottom-all-loaded="allLoaded"
          :auto-fill="false"
          ref="loadmore"
        >
          <ul class="page-loadmore-list order-list">
            <li v-for="(item, index) in list" :key="index">
              <div class="info">
                <div
                  class="ck-box"
                  v-if="activeIndex == 1"
                  :class="{ active: selected.includes(index) }"
                  @click.stop="addSelected(index)"
                ></div>
                <div class="content">
                  <h5 @click="toDetail(item.pid,item.model)">{{ item.mark }}</h5>
                  <div>
                    <p>
                      最晚支付：
                      <span>{{ item.times }}</span>
                    </p>
                    <p>
                      {{ item.title }}
                    </p>
                  </div>
                  <div class="price">
                    <p>
                      拍品金额：
                      <span>¥{{ item.money }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="fns" v-if="activeIndex!=1">
                <button
                  v-if="item.state == 0"
                  class="active"
                  @click.stop="sendPay(item, index)"
                >
                  立即支付
                </button>
                <button v-if="item.state == 1">等待发货</button>
                <button
                  v-if="item.state == 2"
                  class="active"
                  @click.stop="setOrderOk(item, index)"
                >
                  确认收货
                </button>
                <button class="active" @click.stop="toLogistics(item.kuaidi)" v-if="item.state == 2">物流信息</button>
                <button v-if="item.state == 2">等待收货</button>
                <button v-if="item.state == 3">订单完成</button>
                <button v-if="item.vio == 1">已违约</button>
              </div>
            </li>
          </ul>

          <div slot="bottom" class="mint-loadmore-bottom">
            <span
              v-show="bottomStatus !== 'loading'"
              :class="{ 'is-rotate': bottomStatus === 'drop' }"
              >↑</span
            >
            <span v-show="bottomStatus === 'loading'">
              <mt-spinner type="snake"></mt-spinner>
            </span>
          </div>

          <None
            class="y-none"
            value="没有更多数据啦"
            v-if="allLoaded && list.length == 0"
          />
        </mt-loadmore>
      </div>
    </Warpper>
    <Tabber :default="false" :selected="2" />
    <div class="mask" @click.stop="mask = false" v-if="mask">
      <div class="order-address" v-if="addressBox">
        <h5>请确认您的收货信息</h5>
        <ul v-if="addressData">
          <li>
            <label>收货人：</label>
            <p>{{ addressData.name }}</p>
          </li>
          <li>
            <label>手机号码：</label>
            <p>{{ addressData.phone }}</p>
          </li>
          <li>
            <label>收货地址：</label>
            <p>
              {{ addressData.province }} {{ addressData.city }}
              {{ addressData.area }} {{ addressData.add }}
            </p>
          </li>
        </ul>
        <div class="address-fns">
          <span @click.stop="editAddress">修改</span>
          <span @click.stop="onPay">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "components/Tabbar";
import None from "components/None";
import {
  reqGetAddress,
  reqGetMyOrder,
  reqGetToBuy,
  reqSetOrderOk,
  reqGetExpress,
} from "@/network/api";
export default {
  name: "order",
  components: {
    Tabber,
    None,
  },
  created() {
    this.activeIndex = this.$route.query.index || 0;
    this.getAddressData();
  },
  mounted() {
    this.loadBottom();
  },
  data() {
    return {
      first: true,
      mask: false,
      addressBox: false,
      all: false,
      selected: [],
      allLoaded: false,
      bottomStatus: "",
      wrapperHeight: 0,
      list: [],
      activeIndex: -1,
      navList: ["全部", "待支付", "待发货", "待收货", "已完成", "违约"],
      addressData: null,
      page: 0,
      total: 1,
      money: 0,
      goodsObj: null
    };
  },
  methods: {
    toDetail(id,type) {
      // 跳转详情
      this.$router.push({
        name: "detail",
        query: {
          type,
          id
        }
      });
    },
    toLogistics(code){
      if(!code) {
        return this.$toast({message:"暂无物流信息"});
      }
      this.$router.push({
        name:"logistics",
        query:{
          code
        }
      })
    },
    setOrderOk(obj) {
      reqSetOrderOk({
        id: obj.id,
      }).then((res) => {
        const { code, msg, backdata } = res;
        this.$toast({ message: msg });
        if (code != 0) return;
        this.list = [].filter.call(this.list, (item) => {
          return item.id != obj.id;
        });
      });
    },
    getAllMoney() {
      let m = 0;
      this.selected.forEach((i) => {
        m += +this.list[i].money;
      });
      this.money = m;
    },
    getAddressData() {
      reqGetAddress({ id: this.$store.getters.address.id }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return;
        this.addressData = backdata.adds;
      }, false);
    },
    sendPay(obj) {
      this.goodsObj = obj;
      if (this.goodsObj == null) {
        if (this.selected.length == 0) {
          return this.$toast({
            message: "请选择要支付的订单",
          });
        }
      }
      if (this.addressData == null) {
        this.$toast({ message: "请添加收货地址" });
        this.$router.push({ name: "address" });
      } else {
        this.getAddress();
      }
    },
    editAddress() {
      this.$router.push({ name: "address",query:{state:"select"}});
    },
    onPay() {
      // 去支付
      this.addressBox = false;
      let orderid = "";
      if (this.goodsObj != null) {
        orderid = this.goodsObj.id;
      } else {
        if (this.selected.length == 0) return;
        orderid = this.selected.map((i) => this.list[i].id).join(",");
      }
      reqGetToBuy({
        action: "pay_order",
        add_id: this.addressData.id,
        order: orderid,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.$router.push({
          name: "pay",
          query: {
            order: backdata.order,
          },
        });
      });
    },
    getAddress() {
      // 获取用户地址
      this.addressBox = true;
    },
    addSelected(index) {
      if (this.selected.includes(index)) {
        this.selected = this.selected.filter((item) => item != index);
      } else {
        this.selected.push(index);
      }
      this.getAllMoney();
    },
    handleBottomChange(status) {
      this.bottomStatus = status;
    },
    loadBottom() {
      if (this.page >= this.total) return;
      reqGetMyOrder({
        type: [null, 0, 1, 2, 3, 4][this.activeIndex],
        page: this.page + 1,
      }).then((res) => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.page = backdata.now;
        this.total = backdata.count;
        this.list = [...this.list, ...backdata.list];
        if (this.page >= this.total) {
          this.allLoaded = true;
        }
        if (!this.first) {
          this.$refs.loadmore.onBottomLoaded();
        }
      });
    },
  },
  watch: {
    mask(v) {
      this.addressBox = v;
    },
    addressBox(v) {
      this.mask = v;
    },
    all(v) {
      if (v) {
        this.list.forEach((item, index) => {
          this.selected.push(index);
        });
      } else this.selected = [];
      this.getAllMoney();
    },
    activeIndex(v) {
      if (this.$route.query.index == v) return;
      this.page = 0;
      this.total = 1;
      this.allLoaded = false;
      this.list.length = 0;
      this.all = false;
      this.$router.replace({
        name: "order",
        query: {
          index: v,
        },
      });
      this.first = true;
      this.loadBottom();
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
}
.order-address {
  width: 5.3rem;
  height: 4rem;
  top: 50%;
  left: 50%;
  margin-left: -(5.3rem)/2;
  margin-top: -(4rem/2);
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  .address-fns {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.3rem 0.4rem;
    text-align: right;
    span {
      margin-left: 0.47rem;
      cursor: pointer;
      font-size: 0.3rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #359298;
    }
  }
  ul {
    box-sizing: border-box;
    padding: 0.24rem 0.3rem;
    li {
      display: flex;
      margin-bottom: 0.2rem;
      label {
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        width: 1.3rem;
      }
      p {
        flex: 1;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
  h5 {
    height: 0.98rem;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    padding: 0 0.3rem;
    &::after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 1px;
      background: #e1e1e1;
      transform: scaleY(0.5);
    }
  }
}
.order-pay {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.3rem;
  &::after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e1e1e1;
    transform: scaleY(0.5);
  }
  button {
    background: #62ada2;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    padding: 0 0.12rem;
    height: 22px;
    border-radius: 2px;
    border: 0;
    margin-right: 0.16rem;
    color: #fff;
    &:focus {
      outline: 0;
    }
  }
  p {
    span {
      margin-left: 0.16rem;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.ck-box {
  width: 0.36rem;
  height: 0.36rem;
  border: 0.02rem solid #62ada2;
  border-radius: 0.04rem;
  cursor: pointer;
  margin-right: 0.24rem;
  position: relative;
  display: block;
  z-index: 1;
  top: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background-color: #62ada2;
    &::after {
      transform: rotate(45deg) scale(1);
    }
  }
  &::after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    content: " ";
    width: 4px;
    height: 8px;
    transform: rotate(45deg) scale(0);
    transition: transform 0.2s;
  }
}
.order-list > li {
  .fns {
    box-sizing: border-box;
    padding: 0.3rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      width: 1.26rem;
      height: 0.44rem;
      background: #e0e0e0;
      border-radius: 0.04rem;
      border: 0;
      margin-left: 0.12rem;
      cursor: pointer;
      &.active {
        background: #62ada2;
        color: #ffffff;
      }
      &:focus {
        outline: 0;
      }
    }
    &::after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e1e1e1;
      transform: scaleY(0.5);
    }
  }
  .info {
    display: flex;
    box-sizing: border-box;
    padding: 0.42rem 0.42rem 0.28rem 0.31rem;
    position: relative;
    &::after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 6.9rem;
      height: 1px;
      background: #e1e1e1;
      transform: scaleY(0.5) translateX(-50%);
    }
    .content {
      flex: 1;
      h5 {
        width: 100%;
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.39rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.2rem;
        p {
          font-size: 0.24rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 0.32rem;
          span {
            font-size: 0.24rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 0.24rem;
          }
        }
        &.price {
          justify-content: flex-end;
          p {
            span {
              color: #ff1010;
            }
          }
        }
      }
    }
  }
}
.mint-loadmore-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auction-nav {
  width: 100%;
  display: flex;
  align-items: center;
  background: #62ada2;
  height: 44px;
  position: relative;
  li {
    position: relative;
    cursor: pointer;
    flex: 1;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      color: #fffe00;
    }
  }
  .bar {
    content: "";
    width: (100%/6);
    position: absolute;
    bottom: 0;
    left: (100%/6) * -1;
    height: 3px;
    background: linear-gradient(90deg, #fffe00 0%, #fec600 100%);
    z-index: 9;
    transition: 0.12s left ease-out;
  }
}
</style>